@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.ml-\[-0\.15vmin\] {
  margin-left: -.15vmin;
}

.ml-\[-0\.35vmin\] {
  margin-left: -.35vmin;
}

.ml-\[-0\.5vmin\] {
  margin-left: -.5vmin;
}

.ml-\[-1\.45vmin\] {
  margin-left: -1.45vmin;
}

.ml-\[-1vmin\] {
  margin-left: -1vmin;
}

.ml-\[-2\.45vmin\] {
  margin-left: -2.45vmin;
}

.ml-\[-2vmin\] {
  margin-left: -2vmin;
}

.ml-\[-3\.75vmin\] {
  margin-left: -3.75vmin;
}

.ml-\[-4\.75vmin\] {
  margin-left: -4.75vmin;
}

.ml-\[0\.15px\] {
  margin-left: .15px;
}

.ml-\[1\.35vmin\] {
  margin-left: 1.35vmin;
}

.ml-\[10vmin\] {
  margin-left: 10vmin;
}

.ml-\[12vmin\] {
  margin-left: 12vmin;
}

.ml-\[14vmin\] {
  margin-left: 14vmin;
}

.ml-\[1vmin\] {
  margin-left: 1vmin;
}

.ml-\[2vmin\] {
  margin-left: 2vmin;
}

.ml-\[3vmin\] {
  margin-left: 3vmin;
}

.ml-\[53vmin\] {
  margin-left: 53vmin;
}

.ml-\[8vmin\] {
  margin-left: 8vmin;
}

.ml-\[9vmin\] {
  margin-left: 9vmin;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mr-\[1vmin\] {
  margin-right: 1vmin;
}

.mr-\[2vmin\] {
  margin-right: 2vmin;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[-0\.1vmin\] {
  margin-top: -.1vmin;
}

.mt-\[-0\.5vmin\] {
  margin-top: -.5vmin;
}

.mt-\[-0\.65vmin\] {
  margin-top: -.65vmin;
}

.mt-\[-0\.75vmin\] {
  margin-top: -.75vmin;
}

.mt-\[-1\.15vmin\] {
  margin-top: -1.15vmin;
}

.mt-\[-1\.25vmin\] {
  margin-top: -1.25vmin;
}

.mt-\[-1\.45vmin\] {
  margin-top: -1.45vmin;
}

.mt-\[-1\.5vmin\] {
  margin-top: -1.5vmin;
}

.mt-\[-1\.65vmin\] {
  margin-top: -1.65vmin;
}

.mt-\[-1vmin\] {
  margin-top: -1vmin;
}

.mt-\[-2\.45vmin\] {
  margin-top: -2.45vmin;
}

.mt-\[-2\.75vmin\] {
  margin-top: -2.75vmin;
}

.mt-\[-3\.75vmin\] {
  margin-top: -3.75vmin;
}

.mt-\[-3vmin\] {
  margin-top: -3vmin;
}

.mt-\[-4\.5vmin\] {
  margin-top: -4.5vmin;
}

.mt-\[-4\.75vmin\] {
  margin-top: -4.75vmin;
}

.mt-\[-4vmin\] {
  margin-top: -4vmin;
}

.mt-\[-5\.25vmin\] {
  margin-top: -5.25vmin;
}

.mt-\[-6\.25vmin\] {
  margin-top: -6.25vmin;
}

.mt-\[-7\.75vmin\] {
  margin-top: -7.75vmin;
}

.mt-\[0\.25px\] {
  margin-top: .25px;
}

.mt-\[0\.5vmin\] {
  margin-top: .5vmin;
}

.mt-\[0\.75px\] {
  margin-top: .75px;
}

.mt-\[1\.75vmin\] {
  margin-top: 1.75vmin;
}

.mt-\[10vmin\] {
  margin-top: 10vmin;
}

.mt-\[12vmin\] {
  margin-top: 12vmin;
}

.mt-\[13vmin\] {
  margin-top: 13vmin;
}

.mt-\[15vmin\] {
  margin-top: 15vmin;
}

.mt-\[1px\] {
  margin-top: 1px;
}

.mt-\[2\.25vmin\] {
  margin-top: 2.25vmin;
}

.mt-\[2\.5px\] {
  margin-top: 2.5px;
}

.mt-\[2vmin\] {
  margin-top: 2vmin;
}

.mt-\[4vmin\] {
  margin-top: 4vmin;
}

.mt-\[5\.15vmin\] {
  margin-top: 5.15vmin;
}

.mt-\[5vmin\] {
  margin-top: 5vmin;
}

.mt-\[6vmin\] {
  margin-top: 6vmin;
}

.mt-\[7vmin\] {
  margin-top: 7vmin;
}

.mt-\[8vmin\] {
  margin-top: 8vmin;
}

.flex {
  display: flex;
}

.h-1 {
  height: .25rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-20 {
  height: 5rem;
}

.h-28 {
  height: 7rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[100vmin\] {
  height: 100vmin;
}

.h-\[10vh\] {
  height: 10vh;
}

.h-\[10vmin\] {
  height: 10vmin;
}

.h-\[110vmin\] {
  height: 110vmin;
}

.h-\[11vmin\] {
  height: 11vmin;
}

.h-\[12vmin\] {
  height: 12vmin;
}

.h-\[15vmin\] {
  height: 15vmin;
}

.h-\[16vmin\] {
  height: 16vmin;
}

.h-\[18vmin\] {
  height: 18vmin;
}

.h-\[20vmin\] {
  height: 20vmin;
}

.h-\[25vmin\] {
  height: 25vmin;
}

.h-\[29vmin\] {
  height: 29vmin;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[32vmin\] {
  height: 32vmin;
}

.h-\[35vmin\] {
  height: 35vmin;
}

.h-\[40vh\] {
  height: 40vh;
}

.h-\[40vmin\] {
  height: 40vmin;
}

.h-\[48vmin\] {
  height: 48vmin;
}

.h-\[5\.5vmin\] {
  height: 5.5vmin;
}

.h-\[50vmin\] {
  height: 50vmin;
}

.h-\[55vmin\] {
  height: 55vmin;
}

.h-\[5vmin\] {
  height: 5vmin;
}

.h-\[60vmin\] {
  height: 60vmin;
}

.h-\[63vh\] {
  height: 63vh;
}

.h-\[6vmin\] {
  height: 6vmin;
}

.h-\[70vmin\] {
  height: 70vmin;
}

.h-\[75vmin\] {
  height: 75vmin;
}

.h-\[85vmin\] {
  height: 85vmin;
}

.h-\[8vmin\] {
  height: 8vmin;
}

.h-\[90vh\] {
  height: 90vh;
}

.h-\[90vmin\] {
  height: 90vmin;
}

.h-\[95vmin\] {
  height: 95vmin;
}

.h-\[96vmin\] {
  height: 96vmin;
}

.h-\[calc\(100vh-27vmin\)\] {
  height: calc(100vh - 27vmin);
}

.max-h-full {
  max-height: 100%;
}

.w-1 {
  width: .25rem;
}

.w-2 {
  width: .5rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-\[0\.5px\] {
  width: .5px;
}

.w-\[1\.25px\] {
  width: 1.25px;
}

.w-\[1\.25vmin\] {
  width: 1.25vmin;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[100vmin\] {
  width: 100vmin;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-\[105vmin\] {
  width: 105vmin;
}

.w-\[10vmin\] {
  width: 10vmin;
}

.w-\[110vmin\] {
  width: 110vmin;
}

.w-\[11vmin\] {
  width: 11vmin;
}

.w-\[12vmin\] {
  width: 12vmin;
}

.w-\[13vmin\] {
  width: 13vmin;
}

.w-\[20vmin\] {
  width: 20vmin;
}

.w-\[27vmin\] {
  width: 27vmin;
}

.w-\[28vmin\] {
  width: 28vmin;
}

.w-\[29vmin\] {
  width: 29vmin;
}

.w-\[29vw\] {
  width: 29vw;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[30vmin\] {
  width: 30vmin;
}

.w-\[33\%\] {
  width: 33%;
}

.w-\[35\%\] {
  width: 35%;
}

.w-\[35vw\] {
  width: 35vw;
}

.w-\[36vw\] {
  width: 36vw;
}

.w-\[37vw\] {
  width: 37vw;
}

.w-\[38\%\] {
  width: 38%;
}

.w-\[38vmin\] {
  width: 38vmin;
}

.w-\[39vw\] {
  width: 39vw;
}

.w-\[40vmin\] {
  width: 40vmin;
}

.w-\[40vw\] {
  width: 40vw;
}

.w-\[45vw\] {
  width: 45vw;
}

.w-\[5\.5vmin\] {
  width: 5.5vmin;
}

.w-\[50vw\] {
  width: 50vw;
}

.w-\[53vw\] {
  width: 53vw;
}

.w-\[55vmin\] {
  width: 55vmin;
}

.w-\[55vw\] {
  width: 55vw;
}

.w-\[59vmin\] {
  width: 59vmin;
}

.w-\[5vmin\] {
  width: 5vmin;
}

.w-\[60vmin\] {
  width: 60vmin;
}

.w-\[60vw\] {
  width: 60vw;
}

.w-\[62\%\] {
  width: 62%;
}

.w-\[62vw\] {
  width: 62vw;
}

.w-\[65vmin\] {
  width: 65vmin;
}

.w-\[65vw\] {
  width: 65vw;
}

.w-\[66vmin\] {
  width: 66vmin;
}

.w-\[68vw\] {
  width: 68vw;
}

.w-\[70vmin\] {
  width: 70vmin;
}

.w-\[72vw\] {
  width: 72vw;
}

.w-\[75vmin\] {
  width: 75vmin;
}

.w-\[76vmin\] {
  width: 76vmin;
}

.w-\[78\%\] {
  width: 78%;
}

.w-\[78vw\] {
  width: 78vw;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[80vmin\] {
  width: 80vmin;
}

.w-\[80vw\] {
  width: 80vw;
}

.w-\[82vw\] {
  width: 82vw;
}

.w-\[84vw\] {
  width: 84vw;
}

.w-\[85\%\] {
  width: 85%;
}

.w-\[85vw\] {
  width: 85vw;
}

.w-\[86vmin\] {
  width: 86vmin;
}

.w-\[87\%\] {
  width: 87%;
}

.w-\[87vw\] {
  width: 87vw;
}

.w-\[88vw\] {
  width: 88vw;
}

.w-\[89\%\] {
  width: 89%;
}

.w-\[8vmin\] {
  width: 8vmin;
}

.w-\[90\%\] {
  width: 90%;
}

.w-\[90vmin\] {
  width: 90vmin;
}

.w-\[90vw\] {
  width: 90vw;
}

.w-\[91\.75vmin\] {
  width: 91.75vmin;
}

.w-\[91vmin\] {
  width: 91vmin;
}

.w-\[92\%\] {
  width: 92%;
}

.w-\[92vw\] {
  width: 92vw;
}

.w-\[93vw\] {
  width: 93vw;
}

.w-\[94\%\] {
  width: 94%;
}

.w-\[94vw\] {
  width: 94vw;
}

.w-\[95\%\] {
  width: 95%;
}

.w-\[95vmin\] {
  width: 95vmin;
}

.w-\[95vw\] {
  width: 95vw;
}

.w-\[96\%\] {
  width: 96%;
}

.w-\[96\.5\%\] {
  width: 96.5%;
}

.w-\[96\.75\%\] {
  width: 96.75%;
}

.w-\[96vmin\] {
  width: 96vmin;
}

.w-\[96vw\] {
  width: 96vw;
}

.w-\[98\%\] {
  width: 98%;
}

.w-\[99vw\] {
  width: 99vw;
}

.max-w-full {
  max-width: 100%;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-14 {
  column-gap: 3.5rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-7 {
  column-gap: 1.75rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-x-9 {
  column-gap: 2.25rem;
}

.gap-x-\[10vmin\] {
  column-gap: 10vmin;
}

.gap-x-\[7vmin\] {
  column-gap: 7vmin;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-24 {
  row-gap: 6rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-y-9 {
  row-gap: 2.25rem;
}

.gap-y-\[10vmin\] {
  row-gap: 10vmin;
}

.gap-y-\[4\.95vmin\] {
  row-gap: 4.95vmin;
}

.gap-y-\[6vmin\] {
  row-gap: 6vmin;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.rounded-\[0\.45vmin\] {
  border-radius: .45vmin;
}

.rounded-\[1\.5vmin\] {
  border-radius: 1.5vmin;
}

.rounded-\[1vmin\] {
  border-radius: 1vmin;
}

.rounded-\[2px\] {
  border-radius: 2px;
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border-2 {
  border-width: 2px;
}

.border-\[0\.15px\] {
  border-width: .15px;
}

.border-\[0\.35px\] {
  border-width: .35px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-\[2px\] {
  border-width: 2px;
}

.border-b-\[1\.5px\] {
  border-bottom-width: 1.5px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-t-\[0\.5px\] {
  border-top-width: .5px;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-t-\[2px\] {
  border-top-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-\[\#d0cfcf\] {
  --tw-border-opacity: 1;
  border-color: rgb(208 207 207 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(33 33 33 / var(--tw-border-opacity));
}

.border-gray {
  border-color: #808080e2;
}

.border-headerUnderline {
  --tw-border-opacity: 1;
  border-color: rgb(211 211 211 / var(--tw-border-opacity));
}

.border-lightGrey {
  --tw-border-opacity: 1;
  border-color: rgb(242 242 242 / var(--tw-border-opacity));
}

.border-mainText {
  --tw-border-opacity: 1;
  border-color: rgb(63 83 216 / var(--tw-border-opacity));
}

.border-textGrey {
  --tw-border-opacity: 1;
  border-color: rgb(100 100 104 / var(--tw-border-opacity));
}

.bg-\[\#628600\] {
  --tw-bg-opacity: 1;
  background-color: rgb(98 134 0 / var(--tw-bg-opacity));
}

.bg-\[\#6e41c6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(110 65 198 / var(--tw-bg-opacity));
}

.bg-backgroundMain {
  --tw-bg-opacity: 1;
  background-color: rgb(240 242 255 / var(--tw-bg-opacity));
}

.bg-deepViolet {
  --tw-bg-opacity: 1;
  background-color: rgb(110 65 198 / var(--tw-bg-opacity));
}

.bg-gray {
  background-color: #808080e2;
}

.bg-lightBlue {
  --tw-bg-opacity: 1;
  background-color: rgb(87 185 255 / var(--tw-bg-opacity));
}

.bg-lightGrey {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
}

.bg-mainText {
  --tw-bg-opacity: 1;
  background-color: rgb(63 83 216 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.object-contain {
  object-fit: contain;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-20 {
  padding: 5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-9 {
  padding: 2.25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pb-\[18vmin\] {
  padding-bottom: 18vmin;
}

.pb-\[35vmin\] {
  padding-bottom: 35vmin;
}

.pb-\[4vmin\] {
  padding-bottom: 4vmin;
}

.pb-\[6vmin\] {
  padding-bottom: 6vmin;
}

.pb-\[8vmin\] {
  padding-bottom: 8vmin;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[5vmin\] {
  padding-left: 5vmin;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-\[5vmin\] {
  padding-right: 5vmin;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-\[9vmin\] {
  padding-top: 9vmin;
}

.text-center {
  text-align: center;
}

.font-poppins {
  font-family: Poppins;
}

.text-\[1\.85vmin\] {
  font-size: 1.85vmin;
}

.text-\[1\.87vmin\] {
  font-size: 1.87vmin;
}

.text-\[10vmin\] {
  font-size: 10vmin;
}

.text-\[11vmin\] {
  font-size: 11vmin;
}

.text-\[17vmin\] {
  font-size: 17vmin;
}

.text-\[2\.05vmin\] {
  font-size: 2.05vmin;
}

.text-\[2\.15vmin\] {
  font-size: 2.15vmin;
}

.text-\[2\.1vmin\] {
  font-size: 2.1vmin;
}

.text-\[2\.21vmin\] {
  font-size: 2.21vmin;
}

.text-\[2\.25vmin\] {
  font-size: 2.25vmin;
}

.text-\[2\.35vmin\] {
  font-size: 2.35vmin;
}

.text-\[2\.45vmin\] {
  font-size: 2.45vmin;
}

.text-\[2\.65vmin\] {
  font-size: 2.65vmin;
}

.text-\[2\.75vmin\] {
  font-size: 2.75vmin;
}

.text-\[2\.85vmin\] {
  font-size: 2.85vmin;
}

.text-\[2\.8vmin\] {
  font-size: 2.8vmin;
}

.text-\[2\.95vmin\] {
  font-size: 2.95vmin;
}

.text-\[2vmin\] {
  font-size: 2vmin;
}

.text-\[3\.05vmin\] {
  font-size: 3.05vmin;
}

.text-\[3\.15vmin\] {
  font-size: 3.15vmin;
}

.text-\[3\.1vmin\] {
  font-size: 3.1vmin;
}

.text-\[3\.45vmin\] {
  font-size: 3.45vmin;
}

.text-\[3\.4vmin\] {
  font-size: 3.4vmin;
}

.text-\[3\.5vmin\] {
  font-size: 3.5vmin;
}

.text-\[3\.65vmin\] {
  font-size: 3.65vmin;
}

.text-\[3\.75vmin\] {
  font-size: 3.75vmin;
}

.text-\[3\.85vmin\] {
  font-size: 3.85vmin;
}

.text-\[3vmin\] {
  font-size: 3vmin;
}

.text-\[4\.15vmin\] {
  font-size: 4.15vmin;
}

.text-\[4\.1vmin\] {
  font-size: 4.1vmin;
}

.text-\[4\.25vmin\] {
  font-size: 4.25vmin;
}

.text-\[4\.5vmin\] {
  font-size: 4.5vmin;
}

.text-\[4\.65vmin\] {
  font-size: 4.65vmin;
}

.text-\[4\.75vmin\] {
  font-size: 4.75vmin;
}

.text-\[4vmin\] {
  font-size: 4vmin;
}

.text-\[5\.35vmin\] {
  font-size: 5.35vmin;
}

.text-\[5\.5vmin\] {
  font-size: 5.5vmin;
}

.text-\[5\.85vmin\] {
  font-size: 5.85vmin;
}

.text-\[5vmin\] {
  font-size: 5vmin;
}

.text-\[6\.55vmin\] {
  font-size: 6.55vmin;
}

.text-\[6\.5vmin\] {
  font-size: 6.5vmin;
}

.text-\[6\.85vmin\] {
  font-size: 6.85vmin;
}

.text-\[6vmin\] {
  font-size: 6vmin;
}

.text-\[7\.25vmin\] {
  font-size: 7.25vmin;
}

.text-\[7\.65vmin\] {
  font-size: 7.65vmin;
}

.text-\[7vmin\] {
  font-size: 7vmin;
}

.text-\[8\.5vmin\] {
  font-size: 8.5vmin;
}

.text-\[8\.75vmin\] {
  font-size: 8.75vmin;
}

.text-\[8vmin\] {
  font-size: 8vmin;
}

.text-\[9vmin\] {
  font-size: 9vmin;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[5\.25vmin\] {
  line-height: 5.25vmin;
}

.leading-\[5\.35vmin\] {
  line-height: 5.35vmin;
}

.leading-\[5\.56vmin\] {
  line-height: 5.56vmin;
}

.leading-\[5\.65vmin\] {
  line-height: 5.65vmin;
}

.leading-\[5\.6vmin\] {
  line-height: 5.6vmin;
}

.leading-\[5vmin\] {
  line-height: 5vmin;
}

.leading-\[7\.25vmin\] {
  line-height: 7.25vmin;
}

.text-deepViolet {
  --tw-text-opacity: 1;
  color: rgb(110 65 198 / var(--tw-text-opacity));
}

.text-gray {
  color: #808080e2;
}

.text-lightGrey {
  --tw-text-opacity: 1;
  color: rgb(242 242 242 / var(--tw-text-opacity));
}

.text-mainText {
  --tw-text-opacity: 1;
  color: rgb(63 83 216 / var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
}

.text-textGrey {
  --tw-text-opacity: 1;
  color: rgb(100 100 104 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-\[1\.35vmin\] {
  text-underline-offset: 1.35vmin;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

textarea:focus, input:focus {
  outline: none;
}

.mainText {
  margin: 0;
  padding: 0;
  list-style-type: square;
}

.mainText li {
  margin-left: 27px;
}

@media (width >= 768px) {
  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:ml-1 {
    margin-left: .25rem;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:ml-\[-1\.15vmin\] {
    margin-left: -1.15vmin;
  }

  .md\:ml-\[5vmin\] {
    margin-left: 5vmin;
  }

  .md\:ml-\[72vmin\] {
    margin-left: 72vmin;
  }

  .md\:mt-9 {
    margin-top: 2.25rem;
  }

  .md\:mt-\[11vmin\] {
    margin-top: 11vmin;
  }

  .md\:mt-\[12vmin\] {
    margin-top: 12vmin;
  }

  .md\:mt-\[15vmin\] {
    margin-top: 15vmin;
  }

  .md\:mt-\[9px\] {
    margin-top: 9px;
  }

  .md\:h-\[10\.15vmin\] {
    height: 10.15vmin;
  }

  .md\:h-\[10vmin\] {
    height: 10vmin;
  }

  .md\:h-\[15vmin\] {
    height: 15vmin;
  }

  .md\:h-\[17vmin\] {
    height: 17vmin;
  }

  .md\:h-\[27vmin\] {
    height: 27vmin;
  }

  .md\:h-\[30vmin\] {
    height: 30vmin;
  }

  .md\:h-\[35vmin\] {
    height: 35vmin;
  }

  .md\:h-\[3vmin\] {
    height: 3vmin;
  }

  .md\:h-\[40vmin\] {
    height: 40vmin;
  }

  .md\:h-\[5\.65vmin\] {
    height: 5.65vmin;
  }

  .md\:h-\[55vh\] {
    height: 55vh;
  }

  .md\:h-\[6\.5vmin\] {
    height: 6.5vmin;
  }

  .md\:h-\[6\.75vmin\] {
    height: 6.75vmin;
  }

  .md\:h-\[7vmin\] {
    height: 7vmin;
  }

  .md\:h-\[calc\(100vh-10vmin\)\] {
    height: calc(100vh - 10vmin);
  }

  .md\:w-\[10\.15vmin\] {
    width: 10.15vmin;
  }

  .md\:w-\[15vmin\] {
    width: 15vmin;
  }

  .md\:w-\[16vmin\] {
    width: 16vmin;
  }

  .md\:w-\[17vmin\] {
    width: 17vmin;
  }

  .md\:w-\[18vmin\] {
    width: 18vmin;
  }

  .md\:w-\[25vmin\] {
    width: 25vmin;
  }

  .md\:w-\[30vw\] {
    width: 30vw;
  }

  .md\:w-\[3vmin\] {
    width: 3vmin;
  }

  .md\:w-\[44vmin\] {
    width: 44vmin;
  }

  .md\:w-\[45vmin\] {
    width: 45vmin;
  }

  .md\:w-\[50vmin\] {
    width: 50vmin;
  }

  .md\:w-\[58vmin\] {
    width: 58vmin;
  }

  .md\:w-\[6\.5vmin\] {
    width: 6.5vmin;
  }

  .md\:w-\[60vmin\] {
    width: 60vmin;
  }

  .md\:w-\[63vmin\] {
    width: 63vmin;
  }

  .md\:w-\[65vmin\] {
    width: 65vmin;
  }

  .md\:w-\[67vmin\] {
    width: 67vmin;
  }

  .md\:w-\[68vmin\] {
    width: 68vmin;
  }

  .md\:w-\[6vmin\] {
    width: 6vmin;
  }

  .md\:w-\[70vmin\] {
    width: 70vmin;
  }

  .md\:w-\[77vmin\] {
    width: 77vmin;
  }

  .md\:w-\[80vw\] {
    width: 80vw;
  }

  .md\:w-\[98vmin\] {
    width: 98vmin;
  }

  .md\:gap-x-3 {
    column-gap: .75rem;
  }

  .md\:gap-x-36 {
    column-gap: 9rem;
  }

  .md\:gap-x-\[15vmin\] {
    column-gap: 15vmin;
  }

  .md\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .md\:gap-y-\[10vmin\] {
    row-gap: 10vmin;
  }

  .md\:gap-y-\[8\.25vmin\] {
    row-gap: 8.25vmin;
  }

  .md\:p-3 {
    padding: .75rem;
  }

  .md\:p-7 {
    padding: 1.75rem;
  }

  .md\:pl-2 {
    padding-left: .5rem;
  }

  .md\:pl-7 {
    padding-left: 1.75rem;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:text-\[1\.95vmin\] {
    font-size: 1.95vmin;
  }

  .md\:text-\[10vmin\] {
    font-size: 10vmin;
  }

  .md\:text-\[15vmin\] {
    font-size: 15vmin;
  }

  .md\:text-\[2\.05vmin\] {
    font-size: 2.05vmin;
  }

  .md\:text-\[2\.15vmin\] {
    font-size: 2.15vmin;
  }

  .md\:text-\[2\.1vmin\] {
    font-size: 2.1vmin;
  }

  .md\:text-\[2\.25vmin\] {
    font-size: 2.25vmin;
  }

  .md\:text-\[2\.35vmin\] {
    font-size: 2.35vmin;
  }

  .md\:text-\[2\.45vmin\] {
    font-size: 2.45vmin;
  }

  .md\:text-\[2\.55vmin\] {
    font-size: 2.55vmin;
  }

  .md\:text-\[2\.65vmin\] {
    font-size: 2.65vmin;
  }

  .md\:text-\[2\.75vmin\] {
    font-size: 2.75vmin;
  }

  .md\:text-\[2\.85vmin\] {
    font-size: 2.85vmin;
  }

  .md\:text-\[2vmin\] {
    font-size: 2vmin;
  }

  .md\:text-\[3\.15vmin\] {
    font-size: 3.15vmin;
  }

  .md\:text-\[3\.25vmin\] {
    font-size: 3.25vmin;
  }

  .md\:text-\[3\.35vmin\] {
    font-size: 3.35vmin;
  }

  .md\:text-\[3\.45vmin\] {
    font-size: 3.45vmin;
  }

  .md\:text-\[3\.5vmin\] {
    font-size: 3.5vmin;
  }

  .md\:text-\[3vmin\] {
    font-size: 3vmin;
  }

  .md\:text-\[4\.45vmin\] {
    font-size: 4.45vmin;
  }

  .md\:text-\[4\.5vmin\] {
    font-size: 4.5vmin;
  }

  .md\:text-\[4vmin\] {
    font-size: 4vmin;
  }
}

@media (width >= 1024px) {
  .lg\:ml-1 {
    margin-left: .25rem;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-1 {
    margin-top: .25rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:mt-\[-5vmin\] {
    margin-top: -5vmin;
  }

  .lg\:mt-\[-6\.45vmin\] {
    margin-top: -6.45vmin;
  }

  .lg\:h-\[10vh\] {
    height: 10vh;
  }

  .lg\:h-\[13vmin\] {
    height: 13vmin;
  }

  .lg\:h-\[30vmin\] {
    height: 30vmin;
  }

  .lg\:h-\[34vmin\] {
    height: 34vmin;
  }

  .lg\:h-\[45vmin\] {
    height: 45vmin;
  }

  .lg\:h-\[calc\(100vh-12vmin\)\] {
    height: calc(100vh - 12vmin);
  }

  .lg\:w-\[110vmin\] {
    width: 110vmin;
  }

  .lg\:w-\[130vmin\] {
    width: 130vmin;
  }

  .lg\:w-\[13vmin\] {
    width: 13vmin;
  }

  .lg\:w-\[17vmin\] {
    width: 17vmin;
  }

  .lg\:w-\[40vmin\] {
    width: 40vmin;
  }

  .lg\:w-\[45vmin\] {
    width: 45vmin;
  }

  .lg\:w-\[48vw\] {
    width: 48vw;
  }

  .lg\:w-\[50\%\] {
    width: 50%;
  }

  .lg\:w-\[50vmin\] {
    width: 50vmin;
  }

  .lg\:w-\[54vmin\] {
    width: 54vmin;
  }

  .lg\:w-\[55vmin\] {
    width: 55vmin;
  }

  .lg\:w-\[70vmin\] {
    width: 70vmin;
  }

  .lg\:w-\[76\%\] {
    width: 76%;
  }

  .lg\:w-\[80\%\] {
    width: 80%;
  }

  .lg\:w-\[93\%\] {
    width: 93%;
  }

  .lg\:w-\[96vw\] {
    width: 96vw;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:gap-y-\[6vmin\] {
    row-gap: 6vmin;
  }

  .lg\:p-3 {
    padding: .75rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:pb-\[1\.35vmin\] {
    padding-bottom: 1.35vmin;
  }

  .lg\:pb-\[2\.5vmin\] {
    padding-bottom: 2.5vmin;
  }

  .lg\:text-\[3\.45vmin\] {
    font-size: 3.45vmin;
  }

  .lg\:text-\[3\.75vmin\] {
    font-size: 3.75vmin;
  }

  .lg\:text-\[4\.5vmin\] {
    font-size: 4.5vmin;
  }

  .lg\:text-\[2min\] {
    color: 2min;
  }
}

@media (width >= 1536px) {
  .\32 xl\:ml-10 {
    margin-left: 2.5rem;
  }

  .\32 xl\:ml-9 {
    margin-left: 2.25rem;
  }

  .\32 xl\:ml-\[1\.45vmin\] {
    margin-left: 1.45vmin;
  }

  .\32 xl\:mt-2 {
    margin-top: .5rem;
  }

  .\32 xl\:mt-\[3vmin\] {
    margin-top: 3vmin;
  }

  .\32 xl\:mt-\[4vmin\] {
    margin-top: 4vmin;
  }

  .\32 xl\:h-\[12vmin\] {
    height: 12vmin;
  }

  .\32 xl\:h-\[13vmin\] {
    height: 13vmin;
  }

  .\32 xl\:h-\[63vh\] {
    height: 63vh;
  }

  .\32 xl\:h-\[6vmin\] {
    height: 6vmin;
  }

  .\32 xl\:h-\[8vmin\] {
    height: 8vmin;
  }

  .\32 xl\:w-1\/4 {
    width: 25%;
  }

  .\32 xl\:w-\[12vmin\] {
    width: 12vmin;
  }

  .\32 xl\:w-\[13vmin\] {
    width: 13vmin;
  }

  .\32 xl\:w-\[25vw\] {
    width: 25vw;
  }

  .\32 xl\:w-\[45vmin\] {
    width: 45vmin;
  }

  .\32 xl\:w-\[50vmin\] {
    width: 50vmin;
  }

  .\32 xl\:w-\[52vmin\] {
    width: 52vmin;
  }

  .\32 xl\:w-\[85\%\] {
    width: 85%;
  }

  .\32 xl\:w-\[96\%\] {
    width: 96%;
  }

  .\32 xl\:p-4 {
    padding: 1rem;
  }

  .\32 xl\:pb-\[3\.25vmin\] {
    padding-bottom: 3.25vmin;
  }

  .\32 xl\:text-\[1\.98vmin\] {
    font-size: 1.98vmin;
  }

  .\32 xl\:text-\[2\.15vmin\] {
    font-size: 2.15vmin;
  }

  .\32 xl\:text-\[2\.1vmin\] {
    font-size: 2.1vmin;
  }

  .\32 xl\:text-\[2\.25vmin\] {
    font-size: 2.25vmin;
  }

  .\32 xl\:text-\[2\.55vmin\] {
    font-size: 2.55vmin;
  }

  .\32 xl\:text-\[2\.5vmin\] {
    font-size: 2.5vmin;
  }

  .\32 xl\:text-\[2\.65vmin\] {
    font-size: 2.65vmin;
  }

  .\32 xl\:text-\[2vmin\] {
    font-size: 2vmin;
  }

  .\32 xl\:text-\[8\.65vmin\] {
    font-size: 8.65vmin;
  }
}
/*# sourceMappingURL=index.8420b636.css.map */
